<template>
  <section class="container-fluid mt-4 pso-dashboard">
    <div class="d-flex justify-content-between">
      <h4 class="font-weight-bold text-left">
        แผนปฏิบัติราชการด้านพลังงานของจังหวัด{{
          currentProvince | province_name_locale
        }}
      </h4>
      <div class="d-flex">
        <button
          v-if="canReverse"
          type="button"
          class="btn btn-danger mr-3"
          @click="reverseReportVersionToDraft"
        >
          Reject แผน เพื่อนำกลับไปแก้ไข
        </button>
        <router-link
          :to="{ name: 'pso.province_summary' }"
          class="btn btn-outline-secondary text-ipepp"
        >
          <eva-icon name="arrow-ios-back"></eva-icon> ย้อนกลับ
        </router-link>
      </div>
    </div>
    <preview-report
      :fetchFunction="fetchProvinceReports"
      :nationalPlan="currentNationalPlan"
      :province="currentProvince"
    ></preview-report>
  </section>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import PreviewReport from '@/components/PreviewReport.vue';
import { find, some } from 'lodash';

export default {
  name: 'ProvinceEnergyReport',

  components: {
    PreviewReport,
  },

  data() {
    return {
      reports: [],
    };
  },

  created() {},

  mounted() {
    this.fetchProvinceReports({
      nationalPlan: this.currentNationalPlan,
      province: this.currentProvince,
    })
      .then((data) => {
        this.reports = data;
      })
      .catch(() => {});
  },

  computed: {
    ...mapState(['meta', 'user', 'provinces', 'nationalPlans']),
    currentProvince() {
      return find(this.provinces, { iso3166_2: this.$route.params.iso3166_2 });
    },
    currentNationalPlan() {
      return find(this.nationalPlans, {
        year: parseInt(this.$route.params.year),
      });
    },
    canReverse() {
      return some(this.reports, ['version', 3]);
    },
  },

  methods: {
    ...mapActions('province', ['fetchProvinceReports']),
    ...mapActions('pso', ['reverseReportVersion']),
    reverseReportVersionToDraft() {
      this.reverseReportVersion({
        nationalPlan: this.currentNationalPlan,
        province: this.currentProvince,
      }).then((data) => {
        console.log(data);
        window.location.reload();
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
